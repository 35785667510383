/* noto-sans-sc-100normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Noto Sans SC Thin'),
    local('NotoSansSC-Thin'), 
    url('./files/noto-sans-sc-chinese-simplified-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-sc-300normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Noto Sans SC Light'),
    local('NotoSansSC-Light'), 
    url('./files/noto-sans-sc-chinese-simplified-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-sc-400normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Sans SC Regular'),
    local('NotoSansSC-Regular'), 
    url('./files/noto-sans-sc-chinese-simplified-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-sc-500normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Noto Sans SC Medium'),
    local('NotoSansSC-Medium'), 
    url('./files/noto-sans-sc-chinese-simplified-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-sc-700normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Sans SC Bold'),
    local('NotoSansSC-Bold'), 
    url('./files/noto-sans-sc-chinese-simplified-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-sc-900normal - chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Noto Sans SC Black'),
    local('NotoSansSC-Black'), 
    url('./files/noto-sans-sc-chinese-simplified-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-sc-chinese-simplified-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
